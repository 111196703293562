import React from "react";

import { Button, Modal, Row, Container } from "react-bootstrap";
import JotformEmbed from "react-jotform-embed";

import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

var token = window?.localStorage?.token;
var decoded = token ? jwt_decode(token) : "";

function JotFormModal({ show, formId, onClose, onCallBack }) {
  const { t } = useTranslation("translation");
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      backdrop="static"
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{t("Survey")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <JotformEmbed
              src={`https://form.jotformeu.com/${formId}?userId=${decoded?.userId}`}
            />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onCallBack}>
          {t("Save and Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default JotFormModal;
