import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProjectsService } from "../../services/project";
import { ProjectsTable } from "./ProjectsTable";
import ProjectForm from "./ProjectForm";
import { NotifyError, NotifySuccess } from "../../Helpers";

export default function ProjectsList() {
  const { t } = useTranslation("translation");
  const [projects, setProjects] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [projectData, setProjectData] = useState({});
  const [isOpen, setOpen] = useState(false);

  const getProjects = async () => {
    const res = await ProjectsService.getProjects();
    if (res.status === 200) setProjects(res.data);
  };

  const getProjectData = async () => {
    const res = await ProjectsService.getProjectById(selectedId);
    if (res.status === 200) setProjectData(res.data);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedId(null);
    setProjectData({});
  };

  const onAdd = async (data) => {
    const res = await ProjectsService.addProject(data);
    if (res.status === 200) {
      NotifySuccess("Project added successfully");
      getProjects();
      closeModal();
    } else {
      NotifyError("an error occured");
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (selectedId) {
      getProjectData();
    }
  }, [selectedId]);

  return (
    <Fragment>
      <ToastContainer />
      <Row className="row-sm">
        <Col md={12} lg={12}>
          <Card className=" custom-card">
            <Card.Header className=" border-bottom-0 pb-0">
              <div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label className="main-content-label my-auto pt-2">
                    {t("All Projects")}
                  </label>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setOpen(true)}
                  >
                    {t("Create new")}
                  </button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <ProjectsTable
                projects={projects}
                onProjectSelected={(id) => {
                  setSelectedId(id);
                  setOpen(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ProjectForm
        isOpen={isOpen}
        data={projectData}
        onAdd={onAdd}
        onClose={() => closeModal()}
      />
    </Fragment>
  );
}
