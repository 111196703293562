import axiosSettings from "../../api-config/axiosSettings";

export class AdminUserService {
  static async addUser(obj) {
    try {
      return await axiosSettings.post("/admin/adminUsers/", obj);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getUsers() {
    try {
      return await axiosSettings.get("/admin/adminUsers/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async delete(id) {
    try {
      return await axiosSettings.delete(`/admin/adminUsers/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }
}
