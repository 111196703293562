import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateProgress } from "../../../../../../../redux/slices/currentProgress";

function ChangeSessionActions({ timeMeeting }) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { learningSpace } = useSelector((state) => state);
  const { currentProgress } = useSelector((state) => state.currentProgress);

  const activeRsrc = learningSpace?.activeResource;

  const allRecources =
    learningSpace?.activeWeekSection?.learningSpaceSectionResourceTOS;

  const getDocument = (type) => {
    let crntPrgs = { ...currentProgress };
    let count;
    if (type === "next" && activeRsrc.number !== allRecources.length) {
      count = activeRsrc.number + 1;
    } else if (type === "pre" && activeRsrc.number !== 1) {
      count = activeRsrc.number - 1;
    }
    crntPrgs.resourceId = allRecources.find((item) => item.number === count).id;
    dispatch(updateProgress({ currentProgress: crntPrgs }));
  };

  return (
    <div className="d-flex w-100 justify-content-between align-items-center my-2">
      <button
        className={`btn btn-ligth align-items-center d-flex gap-2 ${
          (activeRsrc.number === 1 || timeMeeting > 0) && "disabled"
        }`}
        onClick={() => getDocument("pre")}
      >
        <i className="ti ti-angle-double-left"></i>
        {t("Previous Document")}
      </button>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span className="fw-bold fs-6">
          ({learningSpace?.activeResource?.number}/
          {
            learningSpace?.activeWeekSection.learningSpaceSectionResourceTOS
              ?.length
          }
          )
        </span>
      </div>
      <button
        className={`btn btn-ligth align-items-center d-flex gap-2 ${
          (activeRsrc?.number === allRecources?.length || timeMeeting > 0) &&
          "disabled"
        }`}
        onClick={() => getDocument("next")}
      >
        {t("Next Document")}
        <i className="ti ti-angle-double-right"></i>
      </button>
    </div>
  );
}

export default ChangeSessionActions;
