import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ProgressWrapper from "./components/ProgressWrapper";

function ProgramProgressPage() {
  const { t } = useTranslation("translation");

  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            {t("Progress of programs")}
          </h2>
        </div>
      </div>
      <ProgressWrapper />
    </Fragment>
  );
}

export default ProgramProgressPage;
