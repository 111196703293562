import { v4 as uuidv4 } from "uuid";

export const getID = () => uuidv4();

const getQuestions = (item) => {
  if (
    item?.type === "radio" ||
    item?.type === "dropDown" ||
    item?.type === "checkbox"
  ) {
    return item;
  }
  return null;
};

export const getAllQuestionsThathasOptions = (items) => {
  let array = [];
  items?.forEach((element) => {
    const newQuestion = getQuestions(element);
    if (newQuestion) array.push({ ...newQuestion });
    if (element?.children) {
      element?.children?.forEach((child) => {
        const newQuestion = getQuestions(child);
        if (newQuestion) array.push({ ...newQuestion });
      });
    }
  });
  return array;
};

export const parseItemFromBE = (item) => {
  const newItem = { ...item };

  if (!newItem) return null;

  newItem.displayName = newItem.displayName || "";

  if (newItem.formQuestionOptionTOS && !newItem.questionType && !newItem.type) {
    const children = [...newItem.formQuestionOptionTOS].map((x) =>
      parseItemFromBE(x)
    );
    newItem.children = children;
    delete newItem.formQuestionOptionTOS;
  }

  newItem.placeholder = newItem.placeholder || "";
  newItem.label = newItem.label || "";

  if (
    newItem.type === "RADIO_BUTTON" ||
    newItem.questionType === "RADIO_BUTTON"
  ) {
    newItem.type = "radio";
    delete newItem.hintEn;
    delete newItem.hintTr;
  } else if (
    newItem.type === "SINGLE_TEXT" ||
    newItem.questionType === "SINGLE_TEXT"
  ) {
    newItem.type = "textBox";
  } else if (
    newItem.type === "MULTIPLE_SELECT" ||
    newItem.questionType === "MULTIPLE_SELECT"
  ) {
    newItem.type = "checkbox";
  } else if (
    newItem.type === "DATE_SELECT" ||
    newItem.questionType === "DATE_SELECT"
  ) {
    newItem.type = "datepicker";
  } else if (
    newItem.type === "DROP_DOWN" ||
    newItem.questionType === "DROP_DOWN"
  ) {
    newItem.type = "dropDown";
  } else if (
    newItem.type === "INFO_TEXT" ||
    newItem.questionType === "INFO_TEXT"
  ) {
    newItem.type = "INFO_TEXT";
  }

  if (newItem.formQuestionArrayTOS || newItem.formQuestions) {
    newItem.type = "section";
  }

  if (newItem.formQuestionOptionTOS) {
    newItem.options = [...newItem.formQuestionOptionTOS];
    delete newItem.formQuestionOptionTOS;
  }

  if (newItem.formQuestionTOS) {
    const normalChildren = newItem.formQuestionTOS.map((child) =>
      parseItemFromBE(child)
    );
    newItem.children = normalChildren;
    delete newItem.formQuestionTOS;
  }

  let childItems = [];
  if (newItem.formQuestionArrayTOS) {
    const arrayChildren = newItem.formQuestionArrayTOS.map((child) => {
      const parsedChild = parseItemFromBE(child);
      parsedChild.type = "array";
      return parsedChild;
    });
    childItems = childItems.concat(arrayChildren);
    delete newItem.formQuestionArrayTOS;
  }

  if (newItem.formQuestions) {
    const normalChildren = newItem.formQuestions.map((child) =>
      parseItemFromBE(child)
    );
    childItems = childItems.concat(normalChildren);
    delete newItem.formQuestions;
  }

  if (childItems.length >= 0) {
    newItem.children = (newItem.children || []).concat(childItems);
  }

  delete newItem.questionType;

  return newItem;
};

export const getParsedItemForBE = (item) => {
  const newItem = { ...item };
  if (!newItem) return null;
  if (newItem?.options && newItem?.options?.length > 0) {
    newItem.options = newItem.options.filter((option) => {
      return !(
        (option.optionEn !== undefined && option.optionEn === "") ||
        (option.optionTr !== undefined && option.optionTr === "")
      );
    });
  }

  delete newItem?.displayName;
  if (newItem.options) {
    newItem.formQuestionOptionTOS = [...newItem.options];
    delete newItem.options;
  }
  delete newItem?.placeholder;
  delete newItem?.label;
  newItem.questionType = newItem.type;
  delete newItem.type;
  if (newItem.questionType === "radio") {
    newItem.questionType = "RADIO_BUTTON";
    newItem.hintEn = "";
    newItem.hintTr = "";
  }
  if (newItem.questionType === "textBox") {
    newItem.questionType = "SINGLE_TEXT";
  }
  if (newItem?.conditionQuestionId === "-" || !newItem?.conditionQuestionId) {
    newItem.conditionQuestionId = null;
    newItem.conditionAnswerId = null;
  }
  if (newItem.questionType === "checkbox") {
    newItem.questionType = "MULTIPLE_SELECT";
  }
  if (newItem.questionType === "datepicker") {
    newItem.questionType = "DATE_SELECT";
  }
  if (newItem.questionType === "dropDown") {
    newItem.questionType = "DROP_DOWN";
  }
  if (
    (newItem.questionType === "section" || newItem.questionType === "array") &&
    newItem.children
  ) {
    const arrayChildren = newItem.children
      .filter((x) => x?.type === "array")
      .map((child) => getParsedItemForBE(child));

    const normalChildren = newItem.children
      .filter((x) => x?.type !== "array")
      .map((child) => getParsedItemForBE(child));
    if (arrayChildren.length > 0) {
      newItem.formQuestionArrayTOS = [...arrayChildren];
    }
    if (newItem.questionType === "section") {
      newItem.formQuestions = [...normalChildren];
    } else {
      newItem.formQuestionTOS = [...normalChildren];
    }
    delete newItem.children;
    delete newItem.questionType;
  }
  return newItem;
};

export const handleSortValues = (elements) => {
  elements?.forEach((item, index) => {
    if (item?.type === "section") {
      item.sort = index;
      if (item.children) {
        item.children.forEach((child, idx) => {
          child.sort = idx;
          if (child?.children) {
            child?.children?.forEach((subChild, x) => (subChild.sort = x));
          }
        });
      }
    }
  });
  return elements;
};

export const sortItemsRecursively = (items) => {
  if (!items || !Array.isArray(items)) return items;
  const sortedItems = [...items].sort((a, b) => a.sort - b.sort);
  sortedItems.forEach((item) => {
    if (item.children && Array.isArray(item.children)) {
      item.children = sortItemsRecursively(item.children);
    }
  });
  return sortedItems;
};

export function findParentIdByChildUuid(elements, childUuid) {
  for (const element of elements) {
    if (element.id === childUuid) {
      return element?.id;
    }

    if (element.options) {
      for (const option of element.options) {
        if (option.id === childUuid) {
          return element.id;
        }
      }
    }

    if (element.children && element.children.length > 0) {
      const result = findParentIdByChildUuid(element.children, childUuid);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const handleDefaultProperties = (item) => {
  if (item?.type === "textBox") {
    item.hintEn = "Please type here..";
    item.hintTr = "Lütfen buraya yazın..";
  } else if (item?.type === "dropDown") {
    item.hintEn = "Please select an option";
    item.hintTr = "Lütfen bir seçenek seçin";
  } else if (item?.type === "datepicker") {
    item.hintEn = "Please select a date";
    item.hintTr = "Lütfen bir tarih seçin";
  } else if (item?.type === "array") {
    item.hintEn = "default array hint..";
    item.hintTr = "default array hint..";
  }
};

export const parseAndSortLoadedForm = (res) => {
  const data = res?.data;
  let elements = [];
  data?.formSections?.forEach((item) => {
    const newItem = parseItemFromBE(item);
    elements.push(newItem);
  });
  const sortedElements = sortItemsRecursively(elements);
  return sortedElements;
};

export const getProperAttributeName = (property) => {
  if (property === "labelEn") return "Label for English language";
  if (property === "labelTr") return "Label for Turkish language";
  if (property === "displayNameEn") return "Display Name for English language";
  if (property === "displayNameTr") return "Display Name for Turkish language";
};
