import axiosSettings from "../../api-config/axiosSettings";

export class AuthService {
  static async login(email, password) {
    try {
      return await axiosSettings.post("/auth/login", {
        email,
        password,
      });
    } catch (error) {
      throw Object(error);
    }
  }

  static async getRole(email) {
    try {
      return await axiosSettings.post("/auth/getRole", {
        email,
      });
    } catch (error) {
      return error;
    }
  }

  static async signUpMentee(obj) {
    try {
      return await axiosSettings.post("/auth/signUp/mentee", obj);
    } catch (error) {
      return error;
    }
  }

  static async signUpMentor(obj) {
    try {
      return await axiosSettings.post("/auth/signUp/mentor", obj);
    } catch (error) {
      return error;
    }
  }

  static async forgatPassword(obj) {
    try {
      return await axiosSettings.post("/auth/forgetPassword", obj);
    } catch (error) {
      return error;
    }
  }

  static async resetPassword(obj) {
    try {
      return await axiosSettings.post("/auth/resetPassword", obj);
    } catch (error) {
      return error;
    }
  }
}
