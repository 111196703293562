import React, { useEffect, useState } from "react";
import { Form, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { getPrograms } from "../../../../redux/slices/program";
import { ProgramService } from "../../../../services/program";
import ProgressBarChart from "../ProgressBarChart";

function ProgressWrapper() {
  const { t } = useTranslation("translation");
  const [programDetails, setProgramDetails] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const dispatch = useDispatch();
  const { programs } = useSelector((state) => state.programs);
  const { programId } = useParams();

  const selectedProgramDetails = programs?.content?.find(
    (program) => program?.id === Number(programId.split("=")[1])
  );

  const totalSession = selectedProgramDetails?.sessionEntitySet?.length;

  const getProgramDetails = () => {
    ProgramService.programProgress(programId.split("=")[1]).then((res) => {
      if (res && res?.data?.length > 0) {
        setProgramDetails(res?.data);
      }
    });
  };

  const handleGraphData = (selectedTag) => {
    const currentProgramDetails = [...programDetails];
    let selectedPrograms = [];
    selectedPrograms = currentProgramDetails.filter(
      (x) => x?.tag === selectedTag?.value
    );
    let graphData = {
      completedSession: {
        labels: [],
        values: [],
      },
      completedLink: {
        labels: [],
        values: [],
      },
    };
    selectedPrograms.forEach((item) => {
      const details = selectedProgramDetails.mentorshipEntitySet.find(
        (elm) => elm?.studentEmail === item?.studentEmail
      );

      const mentorsDetail = `${
        details?.studentFirstName
          ? details?.studentFirstName
          : details?.studentEmail
      } ${details?.studentLastName ? details?.studentLastName : ""} / \n ${
        details?.supervisorFirstName
          ? details?.supervisorFirstName
          : details?.supervisorEmail
      } ${details?.supervisorLastName ? details?.supervisorLastName : ""}`;

      let completeCount = 0;
      let linkCount = 0;
      item?.adminProgressEntries.forEach((entry) => {
        if (entry?.isMeetingCompleted) {
          completeCount = completeCount + 1;
        }
        if (entry?.isSurveyFilled) {
          linkCount = linkCount + 1;
        }
      });
      graphData.completedSession.labels.push(mentorsDetail);
      graphData.completedSession.values.push(completeCount);
      graphData.completedLink.labels.push(mentorsDetail);
      graphData.completedLink.values.push(linkCount);
    });
    setGraphData(graphData);
  };

  const handleTags = () => {
    const currentList = [...programDetails];
    let tags = [];
    currentList.forEach((item) => {
      const currentTag = tags.find((e) => e.value === item.tag);
      if (!currentTag) {
        tags.push({ value: item.tag, label: item.tag });
      }
    });
    setTagOptions(tags);
  };

  useEffect(() => {
    if (programId) {
      getProgramDetails();
    }
  }, [programId]);

  useEffect(() => {
    handleTags();
  }, [programDetails]);

  useEffect(() => {
    dispatch(getPrograms());
  }, []);

  return (
    <Row className="row-sm">
      <FormGroup className="form-group w-25">
        <Form.Label className="tx-medium">{t("Select Tag")}</Form.Label>
        <Select
          closeMenuOnSelect={true}
          options={tagOptions}
          placeholder={t("Select Tag")}
          onChange={(e) => {
            handleGraphData(e);
          }}
        />
      </FormGroup>
      <Row className=" row-sm">
        {!graphData ? (
          <div className="fw-bold fs-3 text-warning">
            {t("Please select the tag you want to view")}.
          </div>
        ) : (
          <>
            <ProgressBarChart
              chartTitle={t("Completed Session")}
              color="#ff9b21"
              maxValue={totalSession}
              numbers={graphData?.completedSession?.values}
              chartLabelNames={graphData?.completedSession?.labels}
            />
            <ProgressBarChart
              chartTitle={t("Filled forms")}
              color="#f1388b"
              maxValue={totalSession}
              numbers={graphData?.completedLink?.values}
              chartLabelNames={graphData?.completedLink?.labels}
            />
          </>
        )}
      </Row>
    </Row>
  );
}

export default ProgressWrapper;
