import axiosSettings from "../../api-config/axiosSettings";

export class ProfileService {
  static async getTag() {
    try {
      return await axiosSettings.get("/profile/getTag");
    } catch (error) {
      return error;
    }
  }

  static async getProfile() {
    try {
      return await axiosSettings.get("/profile/user-details");
    } catch (error) {
      return error;
    }
  }

  static async updateProfile(data) {
    try {
      return await axiosSettings.put("/profile/user-details", data);
    } catch (error) {
      return error;
    }
  }
}
