export class LocalStorageService {
  static setAuth = (token) => {
    localStorage.setItem("token", token);
  };

  static setRole = (token) => {
    localStorage.setItem("userRole", token);
  };

  static setLang = (lang) => {
    localStorage.setItem("lang", lang);
  };

  static getAuth = () => {
    return localStorage.getItem("token");
  };

  static getRole = () => {
    return localStorage.getItem("userRole");
  };

  static getLang = () => {
    return localStorage.getItem("lang");
  };

  static clearLocalStorage = () => {
    localStorage.removeItem("token");
  };
}
