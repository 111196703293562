import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthService } from "../../../services/auth";
import { LocalStorageService } from "../../../services/localStorage";
import jwt_decode from "jwt-decode";

const initialState = {
  token: null,
  status: "inital",
  currentRole: null,
};

export const login = createAsyncThunk(
  "/auth/login",
  async (data, { getState }) => {
    return AuthService.login(data?.email, data?.password);
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addRole: (state, action) => {
      state.currentRole = action.payload.role;
    },
    handleSignup: (state, action) => {
      state.token = action?.payload?.data?.token;
      LocalStorageService.setAuth(action?.payload?.data?.token);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action?.payload?.data?.token;
        LocalStorageService.setRole(
          jwt_decode(action?.payload?.data?.token)?.userRole
        );
        LocalStorageService.setAuth(action?.payload?.data?.token);
      })
      .addCase(login.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { addRole, handleSignup } = authSlice.actions;

export default authSlice.reducer;
