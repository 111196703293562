import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { remainingTime } from "../../../../../../../Helpers";
import JotFormModal from "../../../JotFormModal";
import BodyDetails from "../BodyDetails";
import ChangeSessionActions from "../ChangeSessionActions";

function ResoruceBody({ documentUrl, onNextSection, onCompleteForm }) {
  const { t } = useTranslation("translation");
  const { learningSpace } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const { currentProgress } = useSelector((state) => state.currentProgress);
  const start = new Date();
  const end = new Date(
    learningSpace?.activeLearningSpace?.startDate
  )?.getTime();
  const remainingTime = end - start;

  const startMeeting = new Date();
  const endMeeting = new Date(
    learningSpace?.activeWeekSection?.startDate
  )?.getTime();
  const timeMeeting = endMeeting - startMeeting;

  let isBeforeSession = () => {
    let isBefore = false;
    if (learningSpace) {
      isBefore =
        learningSpace?.activeResource?.nameTr?.search("_before_session_") > -1;
    }
    return isBefore;
  };

  if (remainingTime && remainingTime > 0) {
    return (
      <>
        {currentProgress?.changed ? (
          <ChangeSessionActions timeMeeting={timeMeeting} />
        ) : (
          <ProgramStartingMessage
            reminingTime={remainingTime}
            startDate={learningSpace?.activeLearningSpace?.startDate}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="row gap-3 w-100">
        {!currentProgress?.changed &&
          timeMeeting < 0 &&
          learningSpace?.activeResource?.resourceType !== "FORM" && (
            <div className="d-flex w-100 justify-content-end">
              <div className="btn btn-primary" onClick={onNextSection}>
                {t("View Next Resource")}
              </div>
            </div>
          )}
        {/* {(timeMeeting < 0 || isBeforeSession()) && (
          <BodyDetails documentUrl={documentUrl} onIsOpen={setIsOpen} />
        )} */}
        <BodyDetails documentUrl={documentUrl} onIsOpen={setIsOpen} />
        <JotFormModal
          show={isOpen}
          formId={learningSpace?.activeResource?.resourceTr}
          onClose={() => setIsOpen(false)}
          onCallBack={() => {
            setIsOpen(false);
            onCompleteForm();
          }}
        />
      </div>
    );
  }
}

export default ResoruceBody;

function ProgramStartingMessage({ reminingTime, startDate }) {
  const { t } = useTranslation("translation");
  return (
    <div className="card">
      <div className="card-body d-flex flex-column align-items-center gap-3 my-5">
        <div className="fs-4">
          {t("Time to start of new session")}{" "}
          <strong className="text-success">
            <ins>{remainingTime(reminingTime)}</ins>
          </strong>{" "}
        </div>
        <div className="fs-5">
          {t("Starting Date")} : {moment(startDate).format("YYYY-MM-DD")}
        </div>
        <div>{t("The class will be opening automatically")}.</div>
      </div>
    </div>
  );
}
