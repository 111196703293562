import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "./index.css";

export function AdminUsersTable({ users, onUserSelected }) {
  const { t } = useTranslation("translation");
  const columns = [
    {
      name: t("Id"),
      selector: (row) => [row.id],
      sortable: true,
      cell: (row) => <div className="font-weight-bold">{row.id}</div>,
    },
    {
      name: t("Name"),
      sortable: true,
      cell: (row) => <div>{`${row?.firstName} ${row?.lastName}`}</div>,
    },
    {
      name: t("Email"),
      selector: (row) => [row.email],
      sortable: true,
      cell: (row) => <div>{row.email}</div>,
    },
    {
      name: t("Phone"),
      selector: (row) => [row.phone],
      sortable: true,
      cell: (row) => <div>{row.phone}</div>,
    },
    {
      name: (
        <div className="d-flex justify-content-center w-100">
          {t("ACTIONS")}
        </div>
      ),
      selector: (row) => [row.ACTIONS],
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-center w-100 gap-3">
          {/* //Delete */}
          <button
            className="btn btn-sm btn-secondary p-1"
            onClick={() => onUserSelected(row?.id)}
          >
            {t("Edit")}
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: users,
  };

  return (
    <DataTableExtensions {...tableData}>
      <DataTable
        className="overflow-visible"
        columns={columns}
        defaultSortAsc={false}
        pagination
      />
    </DataTableExtensions>
  );
}
