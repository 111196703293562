import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NotifyError, NotifySuccess } from "../../Helpers";
import { AdminUserService } from "../../services/adminUser";
import { AdminUsersTable } from "./AdminUsersTable";
import AdminUserForm from "./AdminUserForm";

export default function AdminUsersList() {
  const { t } = useTranslation("translation");
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [userData, setUserData] = useState({});
  const [isOpen, setOpen] = useState(false);

  const getProjects = async () => {
    const res = await AdminUserService.getUsers();
    if (res.status === 200) setUsers(res.data);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedId(null);
    setUserData({});
  };

  const onAdd = async (data) => {
    const newData = { ...data };
    newData.preferredLang = "EN";
    const res = await AdminUserService.addUser(newData);
    if (res.status === 200) {
      NotifySuccess("User added successfully");
      getProjects();
      closeModal();
    } else {
      NotifyError("an error occured");
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (selectedId) {
      const currentUser = users?.find((x) => x?.id === selectedId);
      if (currentUser) setUserData(currentUser);
    }
  }, [selectedId]);

  return (
    <Fragment>
      <ToastContainer />
      <Row className="row-sm">
        <Col md={12} lg={12}>
          <Card className=" custom-card">
            <Card.Header className=" border-bottom-0 pb-0">
              <div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label className="main-content-label my-auto pt-2">
                    {t("All Projects")}
                  </label>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setOpen(true)}
                  >
                    {t("Create new")}
                  </button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <AdminUsersTable
                users={users}
                onUserSelected={(id) => {
                  setSelectedId(id);
                  setOpen(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AdminUserForm
        isOpen={isOpen}
        data={userData}
        onAdd={onAdd}
        onClose={() => closeModal()}
      />
    </Fragment>
  );
}
