export const CountryEng = [
  { name: "Albania", code: "AL" },
  { name: "Åland Islands", code: "AX" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas (the)", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory (the)", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands (the)", code: "KY" },
  { name: "Central African Republic (the)", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands (the)", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros (the)", code: "KM" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Congo (the)", code: "CG" },
  { name: "Cook Islands (the)", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic (the)", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
  { name: "Faroe Islands (the)", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories (the)", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia (the)", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See (the)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic (the)", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands (the)", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands (the)", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger (the)", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands (the)", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines (the)", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Republic of North Macedonia", code: "MK" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation (the)", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan (the)", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands (the)", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates (the)", code: "AE" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    code: "GB",
  },
  { name: "United States Minor Outlying Islands (the)", code: "UM" },
  { name: "United States of America (the)", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const CountryTr = [
  { code: "TR", name: "Türkiye" },
  { code: "VI", name: "ABD Virgin Adaları" },
  { code: "AF", name: "Afganistan" },
  { code: "AX", name: "Aland Adaları" },
  { code: "DE", name: "Almanya" },
  { code: "US", name: "Amerika Birleşik Devletleri" },
  { code: "UM", name: "Amerika Birleşik Devletleri Küçük Dış Adaları" },
  { code: "AS", name: "Amerikan Samoası" },
  { code: "AD", name: "Andora" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarktika" },
  { code: "AG", name: "Antigua ve Barbuda" },
  { code: "AR", name: "Arjantin" },
  { code: "AL", name: "Arnavutluk" },
  { code: "AW", name: "Aruba" },
  { code: "QU", name: "Avrupa Birliği" },
  { code: "AU", name: "Avustralya" },
  { code: "AT", name: "Avusturya" },
  { code: "AZ", name: "Azerbaycan" },
  { code: "BS", name: "Bahamalar" },
  { code: "BH", name: "Bahreyn" },
  { code: "BD", name: "Bangladeş" },
  { code: "BB", name: "Barbados" },
  { code: "EH", name: "Batı Sahara" },
  { code: "BZ", name: "Belize" },
  { code: "BE", name: "Belçika" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BY", name: "Beyaz Rusya" },
  { code: "BT", name: "Bhutan" },
  { code: "ZZ", name: "Bilinmeyen veya Geçersiz Bölge" },
  { code: "AE", name: "Birleşik Arap Emirlikleri" },
  { code: "GB", name: "Birleşik Krallık" },
  { code: "BO", name: "Bolivya" },
  { code: "BA", name: "Bosna Hersek" },
  { code: "BW", name: "Botsvana" },
  { code: "BV", name: "Bouvet Adası" },
  { code: "BR", name: "Brezilya" },
  { code: "BN", name: "Brunei" },
  { code: "BG", name: "Bulgaristan" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "CV", name: "Cape Verde" },
  { code: "GI", name: "Cebelitarık" },
  { code: "DZ", name: "Cezayir" },
  { code: "CX", name: "Christmas Adası" },
  { code: "DJ", name: "Cibuti" },
  { code: "CC", name: "Cocos Adaları" },
  { code: "CK", name: "Cook Adaları" },
  { code: "TD", name: "Çad" },
  { code: "CZ", name: "Çek Cumhuriyeti" },
  { code: "CN", name: "Çin" },
  { code: "DK", name: "Danimarka" },
  { code: "DM", name: "Dominik" },
  { code: "DO", name: "Dominik Cumhuriyeti" },
  { code: "TL", name: "Doğu Timor" },
  { code: "EC", name: "Ekvator" },
  { code: "GQ", name: "Ekvator Ginesi" },
  { code: "SV", name: "El Salvador" },
  { code: "ID", name: "Endonezya" },
  { code: "ER", name: "Eritre" },
  { code: "AM", name: "Ermenistan" },
  { code: "EE", name: "Estonya" },
  { code: "ET", name: "Etiyopya" },
  { code: "FK", name: "Falkland Adaları (Malvinalar)" },
  { code: "FO", name: "Faroe Adaları" },
  { code: "MA", name: "Fas" },
  { code: "FJ", name: "Fiji" },
  { code: "CI", name: "Fildişi Sahilleri" },
  { code: "PH", name: "Filipinler" },
  { code: "PS", name: "Filistin Bölgesi" },
  { code: "FI", name: "Finlandiya" },
  { code: "FR", name: "Fransa" },
  { code: "GF", name: "Fransız Guyanası" },
  { code: "TF", name: "Fransız Güney Bölgeleri" },
  { code: "PF", name: "Fransız Polinezyası" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GH", name: "Gana" },
  { code: "GN", name: "Gine" },
  { code: "GW", name: "Gine-Bissau" },
  { code: "GD", name: "Granada" },
  { code: "GL", name: "Grönland" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GY", name: "Guyana" },
  { code: "ZA", name: "Güney Afrika" },
  { code: "GS", name: "Güney Georgia ve Güney Sandwich Adaları" },
  { code: "KR", name: "Güney Kore" },
  { code: "CY", name: "Güney Kıbrıs Rum Kesimi" },
  { code: "GE", name: "Gürcistan" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Adası ve McDonald Adaları" },
  { code: "IN", name: "Hindistan" },
  { code: "IO", name: "Hint Okyanusu İngiliz Bölgesi" },
  { code: "NL", name: "Hollanda" },
  { code: "AN", name: "Hollanda Antilleri" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong SAR - Çin" },
  { code: "HR", name: "Hırvatistan" },
  { code: "IQ", name: "Irak" },
  { code: "VG", name: "İngiliz Virgin Adaları" },
  { code: "IR", name: "İran" },
  { code: "IE", name: "İrlanda" },
  { code: "ES", name: "İspanya" },
  { code: "IL", name: "İsrail" },
  { code: "SE", name: "İsveç" },
  { code: "CH", name: "İsviçre" },
  { code: "IT", name: "İtalya" },
  { code: "IS", name: "İzlanda" },
  { code: "JM", name: "Jamaika" },
  { code: "JP", name: "Japonya" },
  { code: "JE", name: "Jersey" },
  { code: "KH", name: "Kamboçya" },
  { code: "CM", name: "Kamerun" },
  { code: "CA", name: "Kanada" },
  { code: "ME", name: "Karadağ" },
  { code: "QA", name: "Katar" },
  { code: "KY", name: "Kayman Adaları" },
  { code: "KZ", name: "Kazakistan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "CO", name: "Kolombiya" },
  { code: "KM", name: "Komorlar" },
  { code: "CG", name: "Kongo" },
  { code: "CD", name: "Kongo Demokratik Cumhuriyeti" },
  { code: "CR", name: "Kosta Rika" },
  { code: "KW", name: "Kuveyt" },
  { code: "KP", name: "Kuzey Kore" },
  { code: "MP", name: "Kuzey Mariana Adaları" },
  { code: "CU", name: "Küba" },
  { code: "KG", name: "Kırgızistan" },
  { code: "LA", name: "Laos" },
  { code: "LS", name: "Lesotho" },
  { code: "LV", name: "Letonya" },
  { code: "LR", name: "Liberya" },
  { code: "LY", name: "Libya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Litvanya" },
  { code: "LB", name: "Lübnan" },
  { code: "LU", name: "Lüksemburg" },
  { code: "HU", name: "Macaristan" },
  { code: "MG", name: "Madagaskar" },
  { code: "MO", name: "Makao S.A.R. Çin" },
  { code: "MK", name: "Makedonya" },
  { code: "MW", name: "Malavi" },
  { code: "MV", name: "Maldivler" },
  { code: "MY", name: "Malezya" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "IM", name: "Man Adası" },
  { code: "MH", name: "Marshall Adaları" },
  { code: "MQ", name: "Martinik" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Meksika" },
  { code: "FM", name: "Mikronezya Federal Eyaletleri" },
  { code: "MD", name: "Moldovya Cumhuriyeti" },
  { code: "MC", name: "Monako" },
  { code: "MS", name: "Montserrat" },
  { code: "MR", name: "Moritanya" },
  { code: "MZ", name: "Mozambik" },
  { code: "MN", name: "Moğolistan" },
  { code: "MM", name: "Myanmar" },
  { code: "EG", name: "Mısır" },
  { code: "NA", name: "Namibya" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NE", name: "Nijer" },
  { code: "NG", name: "Nijerya" },
  { code: "NI", name: "Nikaragua" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Adası" },
  { code: "NO", name: "Norveç" },
  { code: "CF", name: "Orta Afrika Cumhuriyeti" },
  { code: "UZ", name: "Özbekistan" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua Yeni Gine" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Polonya" },
  { code: "PT", name: "Portekiz" },
  { code: "PR", name: "Porto Riko" },
  { code: "RE", name: "Reunion" },
  { code: "RO", name: "Romanya" },
  { code: "RW", name: "Ruanda" },
  { code: "RU", name: "Rusya Federasyonu" },
  { code: "SH", name: "Saint Helena" },
  { code: "KN", name: "Saint Kitts ve Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "PM", name: "Saint Pierre ve Miquelon" },
  { code: "VC", name: "Saint Vincent ve Grenadinler" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome ve Principe" },
  { code: "SN", name: "Senegal" },
  { code: "SC", name: "Seyşeller" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapur" },
  { code: "SK", name: "Slovakya" },
  { code: "SI", name: "Slovenya" },
  { code: "SB", name: "Solomon Adaları" },
  { code: "SO", name: "Somali" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Surinam" },
  { code: "SY", name: "Suriye" },
  { code: "SA", name: "Suudi Arabistan" },
  { code: "SJ", name: "Svalbard ve Jan Mayen" },
  { code: "SZ", name: "Svaziland" },
  { code: "RS", name: "Sırbistan" },
  { code: "CS", name: "Sırbistan-Karadağ" },
  { code: "CL", name: "Şili" },
  { code: "TJ", name: "Tacikistan" },
  { code: "TZ", name: "Tanzanya" },
  { code: "TH", name: "Tayland" },
  { code: "TW", name: "Tayvan" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad ve Tobago" },
  { code: "TN", name: "Tunus" },
  { code: "TC", name: "Turks ve Caicos Adaları" },
  { code: "TV", name: "Tuvalu" },
  { code: "TM", name: "Türkmenistan" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukrayna" },
  { code: "OM", name: "Umman" },
  { code: "UY", name: "Uruguay" },
  { code: "QO", name: "Uzak Okyanusya" },
  { code: "JO", name: "Ürdün" },
  { code: "VU", name: "Vanuatu" },
  { code: "VA", name: "Vatikan" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "WF", name: "Wallis ve Futuna" },
  { code: "YE", name: "Yemen" },
  { code: "NC", name: "Yeni Kaledonya" },
  { code: "NZ", name: "Yeni Zelanda" },
  { code: "GR", name: "Yunanistan" },
  { code: "ZM", name: "Zambiya" },
  { code: "ZW", name: "Zimbabve" },
];
