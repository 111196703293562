import ReactEcharts from "echarts-for-react";
import React from "react";
import { Card, Col } from "react-bootstrap";

function ProgressBarChart({
  chartTitle,
  color,
  chartLabelNames,
  maxValue,
  numbers,
}) {
  const options = {
    grid: { top: 20, right: 40, bottom: 140, left: 40, containLabel: true },
    color: color,
    height: 275,
    xAxis: {
      type: "category",
      data: chartLabelNames,
      axisLabel: {
        rotate: 50,
        height: 50,
        fontSize: 10,
      },
    },
    yAxis: {
      type: "value",
      max: maxValue,
    },
    series: [
      {
        data: numbers,
        type: "bar",
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return (
    <Col md={12} lg={6}>
      <Card className=" custom-card overflow-hidden">
        <Card.Header>
          <h6 className="main-content-label mb-1">{chartTitle}</h6>
          {/* <p className="text-muted  card-sub-title">
            Below is the basic Bar chart example.
          </p> */}
        </Card.Header>
        <Card.Body>
          <div>
            <ReactEcharts option={options}></ReactEcharts>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default ProgressBarChart;
