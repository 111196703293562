import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentService } from "../../../services/appointment";
import { ProgressService } from "../../../services/progress";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { FormsService } from "../../../services/forms";
import { parseAndSortLoadedForm } from "../../forms/CreateForm/helper";
import FormParserModal from "../../forms/CreateForm/FormParserModal";
import CompletedModal from "../../common/CompletedModal";

export default function SupervisorDashboard() {
  const [appointments, setAppointments] = useState([]);
  const [progress, setProgress] = useState({});
  const [isCompleted, setCompleted] = useState(false);

  const getAppointments = async () => {
    const res = await AppointmentService.getAppointment();
    if (res.status === 200) {
      setAppointments(res.data);
    }
  };

  const getProgress = async () => {
    const res = await ProgressService.getSupervisorProgress();
    if (res.status === 200) {
      setProgress(res.data);
    }
  };

  const onUpdate = () => {
    getAppointments();
    getProgress();
  };

  useEffect(() => {
    onUpdate();
  }, []);

  const sortedProgress = progress?.sessionProgresses?.sort(
    (a, b) => a.sessionNumber - b.sessionNumber
  );

  useEffect(() => {
    if (progress && progress?.finalSurvey?.isAvailable) {
      setCompleted(true);
    }
  }, [progress]);

  return (
    <Wrapper>
      <CompletedModal
        show={isCompleted}
        onCompleted={setCompleted}
        hasFinalForm={progress?.finalSurvey?.formId}
      />
      {sortedProgress &&
        sortedProgress?.map((item) => {
          return (
            <SessionCard
              key={item?.sessionNumber}
              item={item}
              appointments={appointments}
              onUpdate={onUpdate}
            />
          );
        })}
    </Wrapper>
  );
}

const ordinalNumber = (i) => {
  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  return ordinals[i - 1] || `${i}th`;
};

const formatDate = (timestamp) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  return new Date(timestamp).toLocaleDateString(undefined, options);
};

const SessionCard = ({ item, appointments, onUpdate }) => {
  const { t } = useTranslation("translation");
  const [formData, setFormData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [formLanguages, setLanguages] = useState({
    isEnglish: true,
    isTurkish: true,
  });

  const sortedAppts = [...appointments].sort((a, b) => a.id - b.id);
  const currentSession = sortedAppts[item?.sessionNumber - 1];

  const showForm = async (id) => {
    const res = await FormsService.getFormById(id);
    if (res.status === 200) {
      const { isEnglish, isTurkish } = res["data"];
      const sortedElements = parseAndSortLoadedForm(res);
      setFormData(sortedElements);
      setIsOpen(true);
      setLanguages({ isEnglish, isTurkish });
    }
  };

  const submitForm = async (data) => {
    console.log(currentSession);
    const res = await FormsService.submitSupervisorForm({
      ...data,
      formId: Number(item?.formId),
      sessionId: Number(currentSession?.sessionId),
    });
    if (res.status === 200) {
      setIsOpen(false);
      onUpdate();
    }
  };

  return (
    <>
      <StyledCard
        className="custom-card position-static"
        hasSession={!!currentSession}
      >
        <Card.Body className="position-static">
          <InfoRow>
            <Title>
              {t(ordinalNumber(item?.sessionNumber))} {t("Session")}
            </Title>
          </InfoRow>
          {currentSession && (
            <>
              <InfoRow>
                <Label>{t("Meeting Title")}:</Label>
                <Content>{currentSession.title}</Content>
              </InfoRow>
              <InfoRow>
                <Label>{t("Meeting Date")}:</Label>
                <Content>{formatDate(currentSession.start)}</Content>
              </InfoRow>
              <InfoRow>
                <Label>{t("Meeting Location")}:</Label>
                <Content>{currentSession.location}</Content>
              </InfoRow>
            </>
          )}
          {item?.formId && (
            <InfoRow>
              <Label>{t("Form Filled")}:</Label>
              <Status isAvailable={item?.isFilled}>
                {item?.isFilled ? (
                  t("Yes")
                ) : item?.isAvailable ? (
                  <StyledButton onClick={() => showForm(item?.formId)}>
                    {t("Fill Form")}
                  </StyledButton>
                ) : (
                  t("No")
                )}
              </Status>
            </InfoRow>
          )}
          <InfoRow>
            <Label>{t("Meeting Completed")}:</Label>
            <Status isAvailable={item?.isAvailable}>
              {item?.isAvailable ? t("Yes") : t("No")}
            </Status>
          </InfoRow>
        </Card.Body>
      </StyledCard>
      <FormParserModal
        isOpen={isOpen}
        data={formData}
        formLanguages={formLanguages}
        onClose={() => {
          setIsOpen(false);
        }}
        onSubmit={(data) => {
          submitForm(data);
        }}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
`;

const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  opacity: ${(props) => (props.hasSession ? "1" : "0.5")};
  transition: opacity 0.3s ease-in-out;
`;

const InfoRow = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const Title = styled.span`
  font-weight: bold;
  color: orange;
  margin-right: 10px;
`;

const Content = styled.span`
  color: #555;
`;

const Status = styled.span`
  color: ${(props) => (props.isAvailable ? "green" : "red")};
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
