import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RecourceDataTypes } from "../../../../../../../enums/dataTypes";
import { NotifyError } from "../../../../../../../Helpers";
import { updateProgress } from "../../../../../../../redux/slices/currentProgress";
import { LearningSpacesService } from "../../../../../../../services/learningSpaces";
import ResoruceBody from "../ResoruceBody";

function ResourceItemCard({
  item,
  isAgendaCard,
  documentUrl,
  isCompleted,
  isOpenCard,
}) {
  const { t } = useTranslation("translation");
  const scrollToElementRef = useRef(null);
  const dispatch = useDispatch();
  const { learningSpace } = useSelector((state) => state);
  const { currentProgress } = useSelector((state) => state.currentProgress);

  const handleTitle = (title) => {
    let currentTitle = title;
    if (currentTitle?.search("_before_session_") > -1) {
      currentTitle = currentTitle.replace("_before_session_", "");
    }
    return currentTitle;
  };

  const selectResource = (item) => {
    let crntPrgs = { ...currentProgress };
    if (crntPrgs?.isSelectedResource && crntPrgs?.resourceId === item?.id) {
      delete crntPrgs["isSelectedResource"];
    } else {
      crntPrgs.isSelectedResource = true;
    }
    crntPrgs["resourceId"] = item?.id;
    crntPrgs["changed"] = true;
    dispatch(updateProgress({ currentProgress: crntPrgs }));
  };

  const goToNextStep = (event, item) => {
    event.stopPropagation();
    try {
      LearningSpacesService.finishedResource(item?.id).then((res) => {
        if (res) {
          selectResource(item);
        }
      });
    } catch (e) {
      NotifyError(e.message);
    }
  };

  useEffect(() => {
    if (isOpenCard && scrollToElementRef?.current) {
      const rect = scrollToElementRef.current.getBoundingClientRect();
      const topOffset = rect.top + window.pageYOffset - 108;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  }, [isOpenCard]);

  let content;

  if (isOpenCard) {
    content = (
      <ContentWrapper>
        <div
          className="d-flex flex-column flex-lg-row w-100 justify-content-between align-items-center"
          onClick={() => selectResource(item)}
        >
          <CardTitle
            item={item}
            learningSpace={learningSpace}
            handleTitle={handleTitle}
          />
          {currentProgress.changed && (
            <div>
              <button
                className="btn btn-primary"
                style={{ minWidth: "150px" }}
                onClick={() => window.location.reload()}
              >
                {t("Go to Current Session")}
              </button>
            </div>
          )}
        </div>
        <ResoruceBody documentUrl={documentUrl} />
      </ContentWrapper>
    );
  } else {
    content = (
      <>
        <LeftSide>
          <CardCoverImage
            item={item}
            isAgendaCard={isAgendaCard}
            learningSpace={learningSpace}
          />
        </LeftSide>
        <RightSide>
          <CardTitle
            item={item}
            learningSpace={learningSpace}
            handleTitle={handleTitle}
          />
        </RightSide>
      </>
    );
  }

  return (
    <Wrapper
      ref={scrollToElementRef}
      className="mb-3"
      onClick={() => {
        if (!isOpenCard) {
          selectResource(item);
        }
      }}
    >
      {content}
    </Wrapper>
  );
}

export default ResourceItemCard;

function ImageSection({ src }) {
  return <img alt="avatar" style={{ width: "75px" }} src={src} />;
}

function CardCoverImage({ item, isAgendaCard, learningSpace }) {
  const currentItem = { ...item };
  if (isAgendaCard) {
    return (
      <ImageSection
        src={require("../../../../../../../assets/img/pngs/checklist.png")}
      />
    );
  } else if (currentItem.resourceType === RecourceDataTypes.file) {
    return (
      <ImageSection
        src={require("../../../../../../../assets/img/pngs/pdffile.png")}
      />
    );
  } else if (currentItem.resourceType === RecourceDataTypes.form) {
    return <div>Form</div>;
  } else if (currentItem.resourceType === RecourceDataTypes.video) {
    const resourcelink = item?.resourceTr;
    if (
      resourcelink?.search("ted") > -1 ||
      resourcelink?.search("youtube") > -1
    ) {
      return (
        <iframe
          width="100%"
          height="100"
          src={`${resourcelink}?controls=0`}
          style={{ borderRadius: "10px" }}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      );
    } else {
      return (
        <ImageSection
          src={require("../../../../../../../assets/img/pngs/web.png")}
        />
      );
    }
  }
  return;
}

function CardTitle({ item, learningSpace, handleTitle }) {
  const { t } = useTranslation("translation");
  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <div>
        <div className="fw-bold fs-5">{t(handleTitle(item.nameTr))}</div>
        <TitleSection>
          <span className="text-muted">
            {learningSpace?.activeSpace?.titleTr}
          </span>
          <span className="fw-bolder">
            {learningSpace?.activeWeekSection?.description}
          </span>
        </TitleSection>
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  border-radius: 5px;
  background: white;
  padding: 0.8rem;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s ease;
  :hover {
    transition: 0.2s ease;
    border-color: red;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const LeftSide = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 10rem;
  height: 6rem;
  border-radius: 10px;
  background: #cbcbcb;
`;

const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProgressSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
