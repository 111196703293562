export const remainingTime = (time) => {
  const oneDay = 24 * 60 * 60 * 1000;
  let seconds = Math.floor(time / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  const diffDays = Math.floor(Math.abs(time / oneDay));
  if (diffDays > 0) {
    return `${diffDays} Gün`;
  } else if (diffDays < 1 && hours > 0) {
    return `${hours} Saat`;
  } else if (hours < 1) {
    return `${minutes} Dakika`;
  }
};
