import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppointmentService } from "../../../services/appointment";

const initialState = {
  appointments: [],
  appointmentStatus: "inital",
};

export const getAppointments = createAsyncThunk(
  "/mentee/appointment",
  async (data, { getState }) => {
    return AppointmentService.getAppointments();
  }
);

const appointmentSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAppointments.pending, (state) => {
        state.appointmentStatus = "loading";
      })
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointmentStatus = "succeeded";
        state.appointments = action?.payload?.data;
      })
      .addCase(getAppointments.rejected, (state) => {
        state.appointmentStatus = "failed";
      });
  },
});

export default appointmentSlice.reducer;
