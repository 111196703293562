import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressService } from "../../../services/progress";

const initialState = {
  currentProgress: {},
  currentProgressStatus: "inital",
};

export const getCurrentProgress = createAsyncThunk(
  "/mentor/learningProgress/currentProgress",
  async (data, { getState }) => {
    return ProgressService.getCurrentProgress();
  }
);

const currentProgressSlice = createSlice({
  name: "currentProgress",
  initialState,
  reducers: {
    updateProgress: (state, action) => {
      state.currentProgress = action.payload.currentProgress;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getCurrentProgress.pending, (state) => {
        state.currentProgressStatus = "loading";
      })
      .addCase(getCurrentProgress.fulfilled, (state, action) => {
        state.currentProgressStatus = "succeeded";
        state.currentProgress = action?.payload?.data;
      })
      .addCase(getCurrentProgress.rejected, (state) => {
        state.currentProgressStatus = "failed";
      });
  },
});

export const { updateProgress } = currentProgressSlice.actions;

export default currentProgressSlice.reducer;
