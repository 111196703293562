import { CHANGE_LOCALE, useLocaleContext } from "./localization/context";

const SWITCH_LOCAL = {
  en: "tr",
  tr: "en",
};
const useLocale = () => {
  const [locale, dispatch] = useLocaleContext();

  const toggleLocale = () => {
    const newLocale = SWITCH_LOCAL[locale];
    dispatch({
      type: CHANGE_LOCALE,
      payload: { locale: newLocale },
    });
  };

  const setLocale = (newLocale) => {
    dispatch({
      type: CHANGE_LOCALE,
      payload: { locale: newLocale },
    });
  };
  return { locale, toggleLocale, setLocale };
};
export default useLocale;
