import axiosSettings from "../../api-config/axiosSettings";
import { NotifyError } from "../../Helpers/Toast";

export class LearningSpacesService {
  static async createUpdateSpaces(body) {
    try {
      return await axiosSettings.post("/admin/learningSpace/", body);
    } catch (error) {
      NotifyError("Please Complete Section Details.");
      throw Object(error);
    }
  }

  static async getSpaces(programId) {
    try {
      return await axiosSettings.get(`/admin/learningSpace/${programId}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async removeSpaces(SpaceId) {
    try {
      return await axiosSettings.delete(`/admin/learningSpace/${SpaceId}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getPresignedUrl(resourceId) {
    try {
      return await axiosSettings.get(
        `/mentor/learning/resourceLink/${resourceId}`
      );
    } catch (error) {
      throw Object(error);
    }
  }

  static async finishedResource(resourceId) {
    try {
      return await axiosSettings.post(
        `/mentor/learning/finishedResource/${resourceId}`
      );
    } catch (error) {
      throw Object(error);
    }
  }

  static async getSpacesForStudent() {
    try {
      return await axiosSettings.get(`/mentor/learning/getLearningSpace`);
    } catch (error) {
      throw Object(error);
    }
  }
}
