import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  learningSpaceWeeks: [],
  activeResource: {},
  activeWeekSection: {},
  activeSpace: {},
  isActive: false,
};

const learningSpaceSlice = createSlice({
  name: "learningSpace",
  initialState,
  reducers: {
    setLearningSpaceWeeks: (state, action) => {
      state.learningSpaceWeeks = action.payload.learningSpaceWeeks;
    },
    setActiveResource: (state, action) => {
      state.activeResource = action.payload.activeResource;
    },
    setActiveWeekSection: (state, action) => {
      state.activeWeekSection = action.payload.activeWeekSection;
    },
    setActiveSpace: (state, action) => {
      state.activeSpace = action.payload.activeSpace;
    },
    setIsActiveLearningSpace: (state, action) => {
      state.isActive = action.payload.isActive;
    },
  },
});

export const {
  setActiveResource,
  setActiveWeekSection,
  setActiveSpace,
  setLearningSpaceWeeks,
  setIsActiveLearningSpace,
} = learningSpaceSlice.actions;

export default learningSpaceSlice.reducer;
