import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

function AdminUserForm({ isOpen, data: defaultData = {}, onAdd, onClose }) {
  const [data, setData] = useState(defaultData);

  const onChange = ({ target: { name, value } }) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  return (
    <Modal show={isOpen}>
      <Modal.Header
        closeButton
        onClick={() => {
          onClose(false);
        }}
      >
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please add first name"
                name="firstName"
                required
                onChange={onChange}
                value={data?.firstName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please add last name"
                name="lastName"
                required
                onChange={onChange}
                value={data?.lastName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Please add email"
                name="email"
                required
                onChange={onChange}
                value={data?.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Please add phone"
                name="phone"
                required
                onChange={onChange}
                value={data?.phone}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Please add password"
                name="password"
                required
                onChange={onChange}
                value={data?.password}
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-gray" onClick={() => onClose(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onAdd(data)}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdminUserForm;
