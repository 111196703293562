import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { updateProgress } from "../../../../../../../redux/slices/currentProgress";

function CustomTab() {
  const { learningSpace } = useSelector((state) => state);
  const { currentProgress } = useSelector((state) => state.currentProgress);
  const dispatch = useDispatch();

  const handleSort = (arr) => {
    if (arr) {
      let currentArr = [...arr];
      if (currentArr && currentArr.length > 1) {
        currentArr = currentArr.sort((a, b) =>
          a?.number > b?.number ? 1 : b?.number > a?.number ? -1 : 0
        );
      }
      return currentArr;
    }
    return;
  };

  function handleTabClick(item) {
    let crntPrgs = { ...currentProgress };
    crntPrgs.weekSectionId = item.id;
    crntPrgs.changed = true;
    delete crntPrgs["isSelectedResource"];
    dispatch(updateProgress({ currentProgress: crntPrgs }));
  }

  const weekSections = learningSpace?.activeSpace?.learningSpaceWeekSections;

  return (
    <Wrapper>
      <InnerWrapper>
        {learningSpace &&
          weekSections &&
          handleSort(weekSections)?.map((item, idx) => {
            return (
              <TabButton
                key={idx}
                isActive={item.id === currentProgress.weekSectionId}
                onClick={() => handleTabClick(item)}
              >
                {item.description}
              </TabButton>
            );
          })}
      </InnerWrapper>
    </Wrapper>
  );
}

export default CustomTab;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 4rem;
  z-index: 1;
  width: 100%;
  background: #eaedf7;
  align-items: center;
  border-bottom: 1px solid #bdbdcd;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow: auto;
  padding-right: 3rem;
`;

const TabButton = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s ease;
  border-bottom: 4px solid transparent;
  :hover {
    background-color: var(--primary-bg-color);
    color: white;
    transition: 0.2s ease;
  }
  ${(props) =>
    props?.isActive &&
    css`
      border-bottom-color: var(--primary-bg-color);
    `};
`;
