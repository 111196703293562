import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ResourceItemCard from "../ResourceItemCard";

function SessionList({ documentUrl }) {
  const { learningSpace } = useSelector((state) => state);
  const { currentProgress } = useSelector((state) => state.currentProgress);

  const handleResourceSort = (resources) => {
    if (resources) {
      let currentResources = [...resources];
      let allResources = {
        beforeSessionResources: [],
        afterSessionResources: [],
      };
      if (currentResources && currentResources?.length > 1) {
        currentResources = currentResources?.sort((a, b) =>
          a?.number > b?.number ? 1 : b?.number > a?.number ? -1 : 0
        );
      }
      currentResources.forEach((item) => {
        if (item?.nameTr?.search("_before_session_") > -1) {
          allResources?.beforeSessionResources.push(item);
        } else {
          allResources?.afterSessionResources.push(item);
        }
      });
      return allResources;
    }
    return;
  };

  const resources =
    learningSpace?.activeWeekSection?.learningSpaceSectionResourceTOS;

  const checkItemCompleted = (item) => {
    if (!currentProgress.changed) {
      const currentResourceNumber = resources?.find(
        (item) => item?.id === currentProgress?.resourceId
      )?.number;
      if (currentResourceNumber) {
        return item?.number < currentResourceNumber;
      }
    }
  };

  return (
    <>
      <AgendaDetails
        documentUrl={documentUrl}
        learningSpace={learningSpace}
        resources={resources}
        currentProgress={currentProgress}
        handleResourceSort={handleResourceSort}
      />
      <ResourcesList
        documentUrl={documentUrl}
        currentProgress={currentProgress}
        learningSpace={learningSpace}
        resources={resources}
        handleResourceSort={handleResourceSort}
        checkItemCompleted={checkItemCompleted}
      />
    </>
  );
}

export default SessionList;

function AgendaDetails({
  documentUrl,
  learningSpace,
  resources,
  currentProgress,
  handleResourceSort,
}) {
  const { t } = useTranslation("translation");
  return (
    <>
      {learningSpace &&
        resources &&
        handleResourceSort(resources)?.beforeSessionResources.length > 0 && (
          <>
            <h6 className="mb-2 fw-bold">{t("Agenda")}</h6>
            {handleResourceSort(resources)?.beforeSessionResources?.map(
              (item, indx) => {
                return (
                  <div className="w-100 border-bottom mb-2" key={indx}>
                    <ResourceItemCard
                      item={item}
                      documentUrl={documentUrl}
                      isAgendaCard
                      isOpenCard={
                        currentProgress?.isSelectedResource &&
                        currentProgress?.resourceId === item.id
                      }
                    />
                  </div>
                );
              }
            )}
          </>
        )}
    </>
  );
}

function ResourcesList({
  learningSpace,
  documentUrl,
  resources,
  currentProgress,
  handleResourceSort,
  checkItemCompleted,
}) {
  const { t } = useTranslation("translation");
  return (
    <>
      <h6 className="mb-2 fw-bold">{t("Resource")}</h6>
      {learningSpace &&
        handleResourceSort(resources)?.afterSessionResources?.map(
          (item, indx) => {
            return (
              <div className="w-100 border-bottom mb-2" key={indx}>
                <ResourceItemCard
                  item={item}
                  documentUrl={documentUrl}
                  isOpenCard={
                    currentProgress?.isSelectedResource &&
                    currentProgress?.resourceId === item.id
                  }
                  isCompleted={checkItemCompleted(item)}
                />
              </div>
            );
          }
        )}
    </>
  );
}
