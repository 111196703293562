import React, { useEffect, useState } from "react";
import DocViewer from "react-doc-viewer";
import { PDFRenderer } from "react-doc-viewer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./FileView.scss";

export default function FileViewer({ item }) {
  const { t } = useTranslation("translation");
  const docs = [{ uri: item, fileType: "pdf" }];
  const [documentTitle, setDocumentTitle] = useState("");
  const { learningSpace } = useSelector((state) => state);

  const handleResourceName = () => {
    let currentTitle = learningSpace?.activeResource?.nameTr;
    if (currentTitle?.search("_before_session_") > -1) {
      currentTitle = currentTitle?.replace("_before_session_", "");
    }
    setDocumentTitle(currentTitle);
  };

  useEffect(() => {
    if (learningSpace) {
      handleResourceName();
    }
  }, [learningSpace]);

  return (
    <div className="position-relative">
      <a
        href={item}
        className="btn btn-secondary btn-sm document_link_btn"
        target="_blank"
        style={{ zIndex: 1 }}
      >
        {t("Please click here, if you can’t see the document")}
      </a>
      <div className="viewerWrapper">
        <DocViewer
          pluginRenderers={[PDFRenderer]}
          documents={docs}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
}
