import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotifyError } from "../../../Helpers";
import { getCurrentProgress } from "../../../redux/slices/currentProgress";
import {
  setActiveResource,
  setActiveSpace,
  setActiveWeekSection,
  setLearningSpaceWeeks,
} from "../../../redux/slices/learningSpace";
import { getProgress } from "../../../redux/slices/progress";
import { LearningSpacesService } from "../../../services/learningSpaces";
import UserLearningSpaceBody from "./components/Body";

function UserLearningSpaceSetupDashboard() {
  const { t } = useTranslation("translation");
  const { learningSpace } = useSelector((state) => state);
  const [documentUrl, setDocumentUrl] = useState("");

  const dispatch = useDispatch();

  const goToNextStep = () => {
    try {
      LearningSpacesService.finishedResource(
        learningSpace?.activeResource?.id
      ).then((res) => {
        if (res) {
          dispatch(getProgress());
          dispatch(getCurrentProgress());
          window.location.reload();
        }
      });
    } catch (e) {
      NotifyError(e.message);
    }
  };

  const getDocumentUrl = () => {
    setDocumentUrl("");
    LearningSpacesService.getPresignedUrl(
      learningSpace?.activeResource?.id
    ).then((res) => {
      console.log(res.data.url);
      setDocumentUrl(res.data.url);
    });
  };

  useEffect(() => {
    if (
      learningSpace?.activeResource &&
      learningSpace?.activeResource?.resourceType === "FILE"
    ) {
      getDocumentUrl();
    }
  }, [learningSpace?.activeResource]);

  return (
    <Fragment>
      <ToastContainer />
      <div className="row row-sm">
        {learningSpace?.learningSpaceWeeks?.length > 0 ? (
          <>
            <div
              className="col-lg-12 col-xxl-12"
              style={{ minHeight: "600px" }}
            >
              <UserLearningSpaceBody
                documentUrl={documentUrl}
                learningSpaceWeeks={learningSpace?.learningSpaceWeeks}
                onCompleteForm={goToNextStep}
              />
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center justify-content-center gap-5 fs-5 fw-bold">
                {t("You dont have learnig space")}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default UserLearningSpaceSetupDashboard;
