import axiosSettings from "../../api-config/axiosSettings";

export class FormsService {
  static async saveForm(formData) {
    try {
      return await axiosSettings.post(`/admin/form/`, formData);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getForms() {
    try {
      return await axiosSettings.get(`/admin/form/`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getFormById(id) {
    try {
      return await axiosSettings.get(`/admin/form/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async submitStudentForm(data) {
    try {
      return await axiosSettings.post(`/student/submit/form/`, data);
    } catch (error) {
      throw Object(error);
    }
  }

  static async submitSupervisorForm(data) {
    try {
      return await axiosSettings.post(`/supervisor/submit/form/`, data);
    } catch (error) {
      throw Object(error);
    }
  }
}
