import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FileViewer from "../../../FileViewer";

function SurveyLinkMessage({ onModalOpen }) {
  const { t } = useTranslation("translation");
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <h4>{t("Please Fill Survey Form")}</h4>
      <div>
        <div
          className="btn btn-danger align-items-center d-flex"
          onClick={onModalOpen}
        >
          {t("Open Survey Form")}
        </div>
      </div>
    </div>
  );
}

function BodyDetails({ documentUrl, onIsOpen }) {
  const { t } = useTranslation("translation");
  const { learningSpace } = useSelector((state) => state);

  const isVideoLink = () => {
    const currentSrc = learningSpace?.activeResource?.resourceTr;
    return currentSrc.search("youtube") > -1 || currentSrc.search("ted") > -1
      ? true
      : false;
  };

  return (
    <div className="col-lg-12 col-xxl-12">
      {learningSpace ? (
        <>
          {learningSpace?.activeResource?.resourceTr ? (
            <>
              {learningSpace?.activeResource?.resourceType === "FORM" && (
                <SurveyLinkMessage onModalOpen={() => onIsOpen(true)} />
              )}
              {learningSpace?.activeResource?.resourceType === "FILE" &&
                documentUrl && <FileViewer item={documentUrl} />}
              {learningSpace?.activeResource?.resourceType === "VIDEO" && (
                <div className="d-block w-100 px-0 justify-content-center">
                  <iframe
                    width="100%"
                    height={isVideoLink() ? "600" : "700"}
                    src={
                      learningSpace && learningSpace?.activeResource?.resourceTr
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex w-100 justify-content-center mt-5 fs-5 fw-bold text-primary">
              {t("No document included")}
            </div>
          )}
        </>
      ) : (
        <Spinner
          as="span"
          animation="border"
          size="xl"
          role="status"
          aria-hidden="true"
        />
      )}
    </div>
  );
}

export default BodyDetails;
