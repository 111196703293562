import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgramService } from "../../../services/program";

const initialState = {
  programs: [],
  programsStatus: "inital",
};

export const getPrograms = createAsyncThunk(
  "/admin/program",
  async (data, { getState }) => {
    return ProgramService.getPrograms();
  }
);

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPrograms.pending, (state) => {
        state.programsStatus = "loading";
      })
      .addCase(getPrograms.fulfilled, (state, action) => {
        state.programsStatus = "succeeded";
        state.programs = action?.payload?.data;
      })
      .addCase(getPrograms.rejected, (state) => {
        state.programsStatus = "failed";
      });
  },
});

export default programsSlice.reducer;
