import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProfileService } from "../../../services/profile";

const initialState = {
  profile: {},
  profileStatus: "inital",
};

export const getUserProfile = createAsyncThunk(
  "/profile/",
  async (data, { getState }) => {
    return ProfileService.getProfile();
  }
);

const profileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.progressStatus = "loading";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.progressStatus = "succeeded";
        state.profile = action?.payload?.data;
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.progressStatus = "failed";
      });
  },
});

export default profileSlice.reducer;
