import axiosSettings from "../../api-config/axiosSettings";

export class AppointmentService {
  static async addAppointment(appointment) {
    try {
      return await axiosSettings.post("/appointments/", appointment);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getAppointment() {
    try {
      return await axiosSettings.get("/appointments/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async deleteAppointment(appointmentId) {
    try {
      return await axiosSettings.delete(`/appointments/${appointmentId}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async updateAppointmentStatus(appointmentId) {
    try {
      return await axiosSettings.put(
        `/appointments/${appointmentId}/meetingCompleted`
      );
    } catch (error) {
      throw Object(error);
    }
  }
}
