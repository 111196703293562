import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import common_en from "./locales/en/translation.json";
import common_tr from "./locales/tr/translation.json";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    resources: {
      en: {
        translation: common_en, // 'common' is our custom namespace
      },
      tr: {
        translation: common_tr,
      },
    },
    fallbackLng: "tr",
    preload: ["en", "tr"],
    supportedLngs: ["en", "tr"],
    keySeparator: false, // we do not use keys in form messages.welcome
    missingKeyNoValueFallbackToKey: "unknownError",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
