import React from "react";
import InterViewDetailCard from "../InterViewDetailCard";
import CustomTab from "./components/CustomTab";
import SessionList from "./components/SessionList";

function UserLearningSpaceBody({ documentUrl }) {
  return (
    <div className="App">
      <CustomTab />
      <InterViewDetailCard />
      <SessionList documentUrl={documentUrl} />
    </div>
  );
}

export default UserLearningSpaceBody;
