export const learningSpaceMockData = {
  items: [
    {
      id: "1",
      order: "1",
      duration: "5 hours",
      name: "Self Development",
      subItems: [
        {
          id: "11",
          name: "Networking",
          files: [
            {
              id: "111",
              name: "Introduction",
              url: "https://www.africau.edu/images/default/sample.pdf",
            },
            {
              id: "112",
              name: "Second Module",
              url: "https://www.africau.edu/images/default/sample.pdf",
            },
          ],
        },
        { name: "Open For Feedback", id: "12" },
        { name: "X Skill", id: "13" },
      ],
      preSurveryLink: "",
      postSurveryLink: "",
    },
    {
      id: "2",
      order: "2",
      name: "Personal development",
      duration: "3 weeks",
      files: [
        {
          id: "22",
          name: "Introduction",
          url: "https://www.africau.edu/images/default/sample.pdf",
        },
      ],
      subItems: [
        { name: "Networking 1", id: "222" },
        { name: "Y Skill", id: "223" },
      ],
      preSurveryLink: "",
      postSurveryLink: "",
    },
  ],
};
