import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProgressService } from "../../../services/progress";

const initialState = {
  progress: {},
  progressStatus: "inital",
};

export const getProgress = createAsyncThunk(
  "/progress/",
  async (data, { getState }) => {
    return ProgressService.getProgress();
  }
);

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProgress.pending, (state) => {
        state.progressStatus = "loading";
      })
      .addCase(getProgress.fulfilled, (state, action) => {
        state.progressStatus = "succeeded";
        state.progress = action?.payload?.data;
      })
      .addCase(getProgress.rejected, (state) => {
        state.progressStatus = "failed";
      });
  },
});

export default progressSlice.reducer;
