import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasTag: false,
};

const profileTagSlice = createSlice({
  name: "profileTag",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.hasTag = action.payload;
    },
  },
});

export const { setTag } = profileTagSlice.actions;

export default profileTagSlice.reducer;
