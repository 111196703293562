import axiosSettings from "../../api-config/axiosSettings";

export class ProgressService {
  static async getProgress() {
    try {
      return await axiosSettings.get("/progress/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async getSupervisorProgress() {
    try {
      return await axiosSettings.get("/progress/supervisor/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async getSurveyInfo(sessionId) {
    try {
      return await axiosSettings.get(`/progress/surveyInfo/${sessionId}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getCurrentProgress() {
    try {
      return await axiosSettings.get("/mentor/learning/currentProgress");
    } catch (error) {
      throw Object(error);
    }
  }
}
