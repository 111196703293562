import { combineReducers } from "redux";
import learningSpace from "./slices/learningSpace";
import auth from "./slices/auth";
import programs from "./slices/program";
import appointment from "./slices/appointment";
import progress from "./slices/progress";
import currentProgress from "./slices/currentProgress";
import profile from "./slices/profileTag";
import userProfile from "./slices/userProfile";

const rootReducer = combineReducers({
  learningSpace,
  auth,
  programs,
  appointment,
  progress,
  currentProgress,
  profile,
  userProfile,
});

export default rootReducer;
