import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { getProgress } from "../../redux/slices/progress";
import { LearningSpacesService } from "../../services/learningSpaces";
import { getCurrentProgress } from "../../redux/slices/currentProgress";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DetailModal from "./components/DetailModal";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  z-index: 1;
  @media (max-width: 992px) {
    margin-top: 10rem;
  }
`;

const localizer = momentLocalizer(moment);

function CalenderPage() {
  const { t } = useTranslation("translation");
  const { currentProgress } = useSelector((state) => state.currentProgress);
  const { progress } = useSelector((state) => state.progress);
  const [events, setEvents] = useState([]);
  const [learningSpaceWeeks, setLearningSpaceWeeks] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const handleEvents = () => {
    let currentEvents = [];

    learningSpaceWeeks?.forEach((item) => {
      const currentWeekTitle = item?.titleTr?.split("-")[1];
      item?.learningSpaceWeekSections?.forEach((element) => {
        let event = {};
        event.title = `${element?.description} - ${currentWeekTitle} `;
        event.start = new Date(moment(element?.startDate));
        event.end = new Date(moment(element?.endDate));
        event.isOnline = element.isOnline;
        event.link = element?.meetingLink;
        currentEvents.push(event);
      });
    });
    setEvents(currentEvents);
  };

  const handleLearningSpace = () => {
    try {
      LearningSpacesService.getSpaces(progress?.programId).then((res) => {
        if (res?.data) {
          setLearningSpaceWeeks(res?.data?.learningSpaceWeeks);
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (learningSpaceWeeks && learningSpaceWeeks.length > 0) {
      handleEvents();
    }
  }, [learningSpaceWeeks]);

  useEffect(() => {
    if (progress && progress?.programId) {
      handleLearningSpace();
    }
  }, [currentProgress]);

  useEffect(() => {
    dispatch(getProgress());
    dispatch(getCurrentProgress());
  }, []);

  function eventStyleGetter(event, start, end, isSelected) {
    var style = {
      backgroundColor: "#f05e22",
      borderColor: "#f05e22",
    };
    return {
      style: style,
    };
  }

  return (
    <Wrapper>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        events={events}
        onSelectEvent={(e) => {
          setSelectedEvent(e);
          setIsOpen(true);
        }}
        messages={{
          next: t("Next"),
          previous: t("Previous"),
          today: t("Today"),
          month: t("Month"),
          week: t("Week"),
          day: t("Day"),
          agenda: t("Agenda"),
        }}
        eventPropGetter={eventStyleGetter}
      />
      <DetailModal
        show={isOpen}
        selectedEvent={selectedEvent}
        handleClose={() => {
          setIsOpen(false);
          setSelectedEvent({});
        }}
      />
    </Wrapper>
  );
}

export default CalenderPage;
