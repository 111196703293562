import {toast} from 'react-toastify'

export function NotifySuccess(msg) {
  toast(msg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: 'success',
  })
}

export function NotifyError(msg, autoClose = 3000) {
  toast(msg, {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: 'error',
  })
}

export function NotifyInfo(msg, time = 3000) {
  toast(msg, {
    position: 'top-right',
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: 'info',
  })
}
