import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

function ProjectForm({ isOpen, data: defaultData = {}, onAdd, onClose }) {
  const [data, setData] = useState(defaultData);

  const onChange = ({ target: { name, value } }) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  return (
    <Modal show={isOpen}>
      <Modal.Header
        closeButton
        onClick={() => {
          onClose(false);
        }}
      >
        <Modal.Title>Project Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <Form.Group>
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please add project name"
                name="projectName"
                required
                onChange={onChange}
                value={data?.projectName}
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-gray" onClick={() => onClose(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onAdd(data)}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectForm;
